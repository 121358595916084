export default {
    setToken(state, str) {
        state.token = str;
    },
    setShowToast(state, value) {
      state.showToast = value;
    },
    setIsNomadic(state, value) {
        state.isNomadic = value
    },
    setWarningText(state, text) {
      state.warningText = text ;
    },
    setPromoList(state, promoList) {
        state.promoList = promoList;
    },
    setLocationList(state, locationList) {
      state.locationList = locationList;
    },
    setUserData(state, data) {
        Object.assign(state.userData, data)
    },
    setUserRole(state, data){
        state.locationId = data.data.current_team?.location[0]?.id
        state.userRole = data.data?.role?.key;
    },
    setUserCurrentStatus(state, status){
        state.userCurrentStatus = state.userStatusDic[status];
    },
    setMopCardData(state, data){
        Object.assign(state.mopCardData, data)
    },
    setActiveTab(state, tabId){
        state.menuList.forEach(tab => {
            tab.active = tab.id === tabId;
            tab.active && (state.activeTab = tab.text);
        })
    },
    setMopButtonHref(state, id){
        state.mopButtonData.map(button => button.href = `/api/queue/${id}/status`)
        // .patch(/api/queue{id}/status)
    },
    setMopMeetingStatus(state, status){
        state.isMopBusy = status;
    },
    setSelectEmployeesList(state, arr){
        state.selectEmployeesList = arr;
    },
    updateMeetingData(state, data){
        //data:{type: 'status',
        //     changed_param: 'status',
        //     param_value: button.id,
        //     index: this.data.index});
        state.meetingsData[data.index].forEach(cell => {
            if (data.field_id === cell.field_id) {
                cell[data.changed_param] = data.param_value;
            }
        });
    },
    setSalesTeamDropdown(state, data){
        state.salesTeamDropdownList = data;
    },
    setMopTeamId(state, id){
        state.mopTeamId = id;
    },
    setMeetingMetricsData(state){
        state.meetingMetricsData.length = 0;
        const statusList = [];
        state.meetingsData.forEach(row => {
            statusList.push(row.find( el => el.field_id === 'status').status);
        })
        state.meetingMetricsData.push({
                name: 'Всего',
                value: state.meetingsData.length,
            },
            {
                name: 'На встрече',
                value:statusList.filter(el => el === 'in_progress').length
            },
            {
                name: 'На выезде',
                value:statusList.filter(el => el === 'on_presentation').length
            },
            {
                name: 'Проконсультировано',
                value: statusList.filter(el => el === 'completed').length
            },
            {
                name: 'Отменено',
                value: statusList.filter(el => el === 'canceled').length
            },
        )
    },
    setTeamsForLocationFormData(state, data){
        state.teamsForLocationFormData = data;
    },
    setEmployeesMetricsData(state){
        state.employeesMetricsData.length = 0;
        const statusList = [];
        state.employeesData.forEach(row => {
            statusList.push(row.find( el => el.field_id === 'status').status);
        });
        state.employeesMetricsData.push({
                name: 'Всего',
                value: state.employeesData.length
            },
            {
                name: 'Свободен',
                value: statusList.filter(el => el === 'active').length
            },
            {
                name: 'Отошел',
                value: statusList.filter(el => el === 'inactive').length
            },
            {
                name: 'На встрече',
                value: statusList.filter(el => el === 'busy').length
            })
    },
    clearMeetingsData(state){
        state.meetingsData.length = 0;
    },
    clearEmployeesData(state){
        state.employeesData.length = 0;
    },
    setProjectsFormData(state, data){
        state.projectsFormData = data;
    },
    setEmployeesData(state, data){
        state.employeesDataRaw = data;
        data.forEach(user=> {
            const table_row = [];
            table_row.push({
                field_id: 'user',
                type: 'select',
                field_name:'Сотрудник',
                subtitle: state?.userRoleDic[user?.current_team?.value?.role || user?.current_team?.role]?.name || 'Сотрудник не выбрал ОП',
                user_name: user.name.value,
                user_id: user.id.value,
                user_photo: user.photo?.value || require('@/assets/user_icon.svg'),
                params: [],
                action_select_url:'',
                status: user.status.value,
                size:'large',
                search:false,
                dropdownList: [],
            });
            table_row.push({
                field_id:'window',
                type: 'input',
                field_name: 'Окно',
                default: user.window.value || 0,
                input_url:`api/user/${user.id.value}?`
            });
            table_row.push({
                field_id:'plan',
                type: 'input',
                field_name: 'План',
                default: user.plan.value || 0,
                input_url:`api/user/${user.id.value}?`
            });
            table_row.push({
                field_id:'status',
                type: 'status',
                field_name: 'Статус',
                status:user.status.value,
                style:'round',
                status_url: `api/user/${user.id.value}`
            });
            table_row.push({
                field_id:'sales_team',
                type: 'select',
                field_name: 'Команда ОП',
                subtitle: '',
                user_name: user.current_team?.value?.name || 'Сотрудник не выбрал ОП',
                user_id: user.id.value,
                user_photo: require('@/assets/point.svg'),
                params: ['user_id','team_id'],
                action_select_url:'/api/user/user_id/team/team_id',
                status: user.status.value,
                size: 'medium',
                search: false,
                dropdownList: state.salesTeamDropdownList,
            });
            table_row.push({
                field_id: 'shows',
                type: 'normal',
                field_name:'Показы',
                text: user.today_queues_count,
            });
            table_row.push({
                field_id: 'lang',
                type: 'select',
                field_name:'Язык',
                subtitle: '',
                user_name: user.languages?.value?.length > 1 ?  state.langDic[3].name : state.langDic[user.languages?.value[0]?.id]?.name || 'Язык не выбран',
                user_id: user.id.value,
                user_photo: user.languages?.value?.length > 1 ?  state.langDic[3].photo : state.langDic[user.languages?.value[0]?.id]?.photo,
                params: [],
                action_select_url:`/api/user/${user.id?.value}/languages?`,
                status: user.status?.value,
                size:'medium',
                search:false,
                dropdownList: Object.values(state.langDic),
            });
            table_row.push({
                field_id:'nomad',
                type: 'normal',
                field_name: 'Номад',
                text: user.nomad.value ? 'Номад' : 'Штат',
            });
            table_row.push({
                field_id:'datepicker',
                type: 'datepicker',
                field_name: 'Дата выходного',
                user_id:user.id.value,
                start_date:user.absence_from.value,
                end_date: user.absence_to.value,

            });
            state.employeesData.push(table_row);
        })
    },
    setMeetingsData(state, data){
        Object.assign(state.propsMeetingData, data);
        data.forEach(meeting => {
            const table_row = [];
            table_row.push({
                field_id: 'project',
                type: 'normal',
                field_name:'Проект',
                text: meeting.project.value.name,
            });
            table_row.push({
                field_id: 'phone_number',
                type: 'link',
                field_name:'Телефон',
                text: meeting.contact_phone.value,
                href: `crm/contact/details/${meeting.bitrix_contact_id.value}/`
            });
            table_row.push({
                field_id: 'deal',
                type: 'link',
                field_name:'Сделка',
                text: meeting.bitrix_deal_id.value,
                href:`crm/deal/details/${meeting.bitrix_deal_id.value}/`
            });
            table_row.push({
                field_id: 'lang',
                type: 'status',
                field_name:'Язык',
                status: meeting.lang.value.id,
                style:'square'
            });
            table_row.push({
                field_id: 'status',
                type: 'status',
                field_name:'Этап',
                status: meeting.status.value,
                style:'round'
            });
            table_row.push({
                field_id: 'date',
                type: 'normal',
                field_name:'Дата',
                date: true,
                finishDate: meeting.closed_at.value,
                text: meeting.created_at.value,
            });
            table_row.push({
                field_id: 'user',
                type: 'select',
                field_name:'Сотрудник',
                user_name: meeting.user.value.name,
                user_id: meeting.user.value.id,
                user_photo: meeting.user.value.photo || require('@/assets/user_icon.svg'),
                params: ['userId', 'meetingId'],
                meeting_id:meeting.id.value,
                action_select_url:`/api/queue/meetingId/user/userId`,
                status: meeting.status.value,
                size:'large',
                search:true,
                dropdownList:Object.values(state.selectEmployeesList) || [],
            });
            table_row.push({
                field_id: 'action',
                type: 'action',
                field_name:'Действие',
                href: `/api/queue/${meeting.id.value}/status`,
                status: meeting.status.value,
                actionList:state.meetingsActionList,
            });
            state.meetingsData.push(table_row);
        })
    },
    clearUserData(state){
        state.addUserFormData.length = 0;
        state.userDataInAdminTable.length = 0;

    },
    clearTeamData(state){
        state.addTeamFormData.length = 0;
        state.teamDataInAdminTable.length = 0;
    },
    clearLocationData(state) {
      state.addLocationFormData.length = 0;
      state.locationDataInAdminTable.length = 0;
    },
    setUsersFieldFormData(state, data){
        state.usersFieldData = data
    },
    showUserVisibleFields(state, data){
        Object.keys(data).forEach(key => {
            const updated = state.addUserFormData.find(field => field.field_id === key);
            updated && (updated.show = data[key]);
        });
    },
    showTeamVisibleFields(state, data){
        Object.keys(data).forEach(key => {
            const updated = state.addTeamFormData.find(field => field.field_id === key);
            updated && (updated.show = data[key]);
        });
    },
    showLocationVisibleFields(state, data){
        Object.keys(data).forEach(key => {
            const updated = state.addLocationFormData.find(field => field.field_id === key);
            updated && (updated.show = data[key]);
        });
    },
    setUserFormData(state){
        state.addUserFormData.push({
            field_id:'user_edit',
            type: 'label',
            value: '',
            field_name: 'Вы редактируете',
            show: false,
        });
        state.addUserFormData.push({
            field_id: 'b_24_user',
            type: 'select',
            field_name:'Выберите сотрудника из Битрикс24',
            value:'',
            placeholder:'Выберите сотрудника',
            user_id: '',
            field_icon: require('@/assets/user_icon.svg'),
            multiple:false,
            search:true,
            dropdownList: state.usersFieldData || [],
            show: true,
        });
        state.addUserFormData.push({
            field_id: 'name',
            type: 'input',
            field_name: 'Имя',
            value: '',
            placeholder: 'Введите имя',
            width: 'full',
            show: true,
        });
        state.addUserFormData.push({
            field_id: 'last_name',
            type: 'input',
            field_name: 'Фамилия',
            value: '',
            placeholder: 'Введите фамилию',
            width: 'full',
            show: true,
        });
        state.addUserFormData.push({
            field_id: 'position',
            type: 'select',
            field_name:'Должность',
            value:'',
            placeholder:'Выберите должность',
            user_id: '',
            field_icon: false,
            search:false,
            dropdownList: Object.values(state.userRoleDic) || [],
            width: 'full',
            show: true,
        });
        state.addUserFormData.push({
            field_id: 'projects',
            type: 'select',
            field_name:'Выберите проект',
            value:'',
            placeholder:'Выберите проект, в которых участвует сотрудник',
            user_id: '',
            field_icon: false,
            search:true,
            multiple:true,
            dropdownList: state.projectsFormData || [],
            width: 'full',
            show: true,
        });
        state.addUserFormData.push({
            type:'row',
            fields:[{
                field_id: 'language',
                type: 'select',
                field_name:'Язык общения',
                value:'',
                placeholder:'Выберите языки общения',
                user_id: '',
                field_icon: false,
                search:false,
                dropdownList: Object.values(state.langDic) ||[],
                width: 'half',
                show: true,
            },{
                field_id: 'nomad',
                type: 'select',
                field_name:'Признак номада',
                value:'',
                placeholder:'Штатный сотрудник',
                user_id: '',
                field_icon: false,
                search:false,
                dropdownList: Object.values(state.nomadDic)  || [],
                width: 'half',
                show: true,
            }]
        })
        state.addUserFormData.push({
            field_id: 'user_team',
            type: 'select',
            field_name:'Команда ОП',
            value:'',
            placeholder:'Выберите команду ОП',
            user_id: '',
            field_icon: false,
            multiple:true,
            search:true,
            dropdownList:  state.salesTeamDropdownList || [],
            width: 'full',
            show: true,
        });
    },
    setTeamFormData(state, data){
        state.addTeamFormData.push({
            field_id:'team_edit',
            value: '',
            type: 'label',
            field_name: 'Вы редактируете',
            show: false,
        });
        state.addTeamFormData.push({
            field_id: 'name',
            type: 'input',
            field_name: 'Название',
            value: '',
            placeholder: 'Введите название ОП',
            width: 'full',
            show: true,
        });
        state.addTeamFormData.push({
            field_id: 'location_id',
            type: 'select',
            field_name:'Локация',
            value:'',
            placeholder:'Введите название локации',
            user_id: '',
            field_icon: false,
            multiple:false,
            search:true,
            dropdownList:  data || [],
            width: 'full',
            show: true,

        })
    },
    setAddressList(state, data){
        data = [...data, data.map(el => el.name = el.VALUE)];
        data = [...data, data.map(el => el.id = el.ID)];
        state.addressList = [...data];
    },
    setLocationFormData(state){
        const data = state.addressList
        state.addLocationFormData.push({
            field_id:'location_edit',
            value: '',
            type: 'label',
            field_name: 'Вы редактируете',
            show: false,
        });
        state.addLocationFormData.push({
            field_id: 'name',
            type: 'input',
            field_name: 'Название',
            value: '',
            placeholder: 'Введите название локации',
            width: 'full',
            show: true,
        });
        state.addLocationFormData.push({
            field_id: 'wazzup',
            type: 'input',
            field_name: 'Номер WhatsApp',
            value: '',
            placeholder: 'Введите номер Whatsapp',
            width: 'full',
            show: true,
        });
        state.addLocationFormData.push({
            field_id: 'address',
            type: 'select',
            field_name:'Адрес',
            value:'',
            placeholder:'Выберите адрес из списка',
            user_id: '',
            field_icon: false,
            search:true,
            multiple:false,
            dropdownList: data,
            width: 'full',
            show: true,
        })
        state.addLocationFormData.push({
            field_id: 'teams',
            type: 'select',
            field_name:'Команда ОП',
            value:'',
            placeholder:'Выберите команду ОП',
            user_id: '',
            field_icon: false,
            search:true,
            multiple:true,
            dropdownList: state.teamsForLocationFormData ||[],
            width: 'full',
            show: true,
        })
    },
    updateAddUserFormData(state, updateObj){
        Object.keys(updateObj).forEach(key => {
            if (key === 'nomad' || key === 'language' ) {
                const row = state.addUserFormData.find(field => field.type === 'row');
                const updated = row.fields.find(field => field.field_id === key)
                updated && (updated.value = updateObj[key]);
            }
            else {
                const updated = state.addUserFormData.find(field => field.field_id === key);
                updated && (updated.value = updateObj[key]);
            }

        });
    },
    updateAddTeamFormData(state, updateObj){
        Object.keys(updateObj).forEach(key => {
            const updated = state.addTeamFormData.find(field => field.field_id === key);
            updated && (updated.value = updateObj[key]);
        });
    },
    updateAddLocationFormData(state, updateObj){
        Object.keys(updateObj).forEach(key => {
            const updated = state.addLocationFormData.find(field => field.field_id === key);
            updated && (updated.value = updateObj[key]);
        });
    },
    setParamsAddUserFormData(state, updateObj){
        state.addUserFormDataParams = Object.assign(state.addUserFormDataParams, updateObj);
    },
    setParamsAddTeamFormData(state, updateObj){
        state.addTeamFormDataParams = Object.assign(state.addTeamFormDataParams, updateObj);
    },
    setParamsAddLocationFormData(state, updateObj){
        state.addLocationFormDataParams = Object.assign(state.addLocationFormDataParams, updateObj);
    },
    setUserDataInAdminTable(state, data){
        if (!data?.length) {
            state.userDataInAdminTable = [];
            state.propsUserDataInAdminTable = [];
            return
        }
        data.forEach(user=> {
            const table_row = [];
            table_row.push({
                field_id: 'user',
                type: 'select',
                field_name: 'Сотрудник',
                value:'',
                subtitle: state.userRoleDic[user?.current_team?.value?.role]?.name || 'Сотрудник не выбрал ОП',
                role_id: user.role.value.id,
                user_name: user.name.value,
                user_id: user.id.value,
                user_photo: user.photo.value || require('@/assets/user_icon.svg'),
                params: [],
                action_select_url: '',
                status: '',
                size: 'large',
                search: false,
                dropdownList: [],
                bitrix_id: user.bitrix_id?.value,
                photo: user.photo?.value,
                email: user.email?.value,
                projects_value: user.projects.value.map(el => el.name),
                project_list: user.projects.value.map(el => el.id),
                op_value: user.teams.value.map(el => el.name),
                op_id: user.teams.value.map(el => el.id),
                nomad: state.nomadDic[+user.nomad.value].name,
                lang: user.languages?.value?.length > 1 ?  state.langDic[3].name : state.langDic[user.languages?.value[0]?.id]?.name || 'Язык не выбран',
            });
            table_row.push({
                field_id: 'action',
                type: 'action',
                field_name: 'Действие',
                href: '',
                actionList:state.adminActionList,
                status: '',
            });
            state.userDataInAdminTable.push(table_row);
            state.propsUserDataInAdminTable.push(table_row)
        });
    },
    setTeamDataInAdminTable(state, data){
        if (!data?.length) {
            state.teamDataInAdminTable = [];
            state.propsTeamDataInAdminTable = [];
            return
        }
        data.forEach(team => {
            const table_row = [];
            table_row.push({
                field_id: 'team',
                type: 'select',
                field_name: 'Отдел продаж',
                value:'',
                subtitle: team.location.name || 'Локация не выбрана',
                user_name: team.name,
                user_id: team.location.id,
                team_id:team.id,
                user_photo: require('@/assets/team.svg'),
                params: [],
                action_select_url: '',
                status: '',
                size: 'large',
                search: false,
                dropdownList: [],
            });
            table_row.push({
                field_id: 'action',
                type: 'action',
                field_name: 'Действие',
                href: '',
                actionList:state.adminActionList,
                status: '',
            });
            state.teamDataInAdminTable.push(table_row);
            state.propsTeamDataInAdminTable.push(table_row);

        });
    },
    setLocationDataInAdminTable(state, data){
        if (!data?.length) {
            state.locationDataInAdminTable = [];
            state.propsLocationDataInAdminTable = [];
            return
        }
        data.forEach(location => {
            const table_row = [];
            table_row.push({
              field_id: "location_add",
              type: "select",
              field_name: "Локация",
              value: "",
              subtitle: " ",
              code: location.code,
              user_name: location.name,
              wazzup: location.wazzup,
              user_id: location.id,
              address: state.addressList.find(
                (el) => +el.id === +location.address
              )?.name,
              address_id: location.address,
              user_photo: require("@/assets/team.svg"),
              params: [],
              action_select_url: "",
              status: "",
              size: "large",
              search: false,
              dropdownList: [],
              op_value: location.teams.map((el) => el.name),
              op_id: location.teams.map((el) => el.id),
            });
            table_row.push({
                field_id: 'action',
                type: 'action',
                field_name: 'Действие',
                href: '',
                actionList:state.locationActionList,
                status: '',
            });
            state.locationDataInAdminTable.push(table_row);
            state.propsLocationDataInAdminTable.push(table_row);
        });
    },
    updateUserDataAdminTable(state, data){
        state.userDataInAdminTable.length = 0;
        state.userDataInAdminTable = data;
    },
    updateTeamDataAdminTable(state, data){
        state.teamDataInAdminTable.length = 0;
        state.teamDataInAdminTable = data;
    },
    updateLocationDataAdminTable(state, data){
        state.locationDataInAdminTable.length = 0;
        state.locationDataInAdminTable = data;
    }
}